<!-- 区校一体化解决方案 -->
<template>
  <div class="solution_div">
		<div class="top-img">
      <img src="static/lvya/solution/solution03.jpg" alt="">
    </div>
		<div class="module module-01">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">系统框架</p>
          <p class="line"></p>
          <p class="text">以“平台+工具+理念”建设区域教育质量提升平台，改变早期的资源共享模式，重点打造区域内优质示范校，在信息技术的支撑下将示范校的优质教育模式快速复制、示范到其他学校，带动每一所学校达到示范标准，推动区域优质教育高质量均衡发展。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/solution/solution07.png" alt="">
      </div>
		</div>
		<div class="module module-02">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">典型功能</p>
          <p class="line"></p>
          <p class="text">将现代化管理思想赋能区域实现区域现代化治理。在区域端进行顶层规划，从区域教育发展的实际出发，设计以育人优先且具有未来教育领先水平的信息化平台，为区域教育发展规划、学校发展、学生成长、教师发展提供支撑，形成政校分开、权责明确、统筹协调、规范有序的教育管理体制。</p>
        </div>
				<div class="list-flex wow animate__fadeInLeft">
					<div class="list">
						<img src="static/lvya/solution/solution08.png" alt="">
						<p class="p1">区域综合管理</p>
						<p class="p2">对各类业务系统进行有机整合，形成具有当地特色的区域综合管理平台。</p>
					</div>
					<div class="list">
						<img src="static/lvya/solution/solution09.png" alt="">
						<p class="p1">区域学生培养</p>
						<p class="p2">统筹学生培养工作，解决课程标准把握和学生学生培养方案落地问题。</p>
					</div>
					<div class="list">
						<img src="static/lvya/solution/solution10.png" alt="">
						<p class="p1">区域教师发展</p>
						<p class="p2">统筹教师发展工作，解决教师“想提升”和“能提升”的问题。</p>
					</div>
					<div class="list">
						<img src="static/lvya/solution/solution11.png" alt="">
						<p class="p1">区域教学</p>
						<p class="p2">统筹教学质量工作，解决教师教学质量与学生学习效率全面提升的问题。</p>
					</div>
					<div class="list">
						<img src="static/lvya/solution/solution12.png" alt="">
						<p class="p1">区域教育质量评估</p>
						<p class="p2">帮助教育局落实教育质量评价方案的制定、教育质量督导评价体系的下发、成果的审核，配套相应考核与诊断机制，实现教育局端对辖区学校的教育督导与评估工作。</p>
					</div>
				</div>
      </div>
		</div>
		<div class="module module-03">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">方案特色</p>
          <p class="line"></p>
        </div>
				<div class="list-flex wow animate__fadeInUp">
					<div class="list">
						<img src="static/lvya/solution/solution04.png" alt="">
						<p class="p1">面向区域的顶层设计</p>
						<p class="p2">由顶层开始具体设计，在区域端，充分发挥区教育局的指导作用，统筹制定区域的人才培养指标与评价标准。在学校端，利用区域提供的信息化工具和方法论，将育人方案落实到每一场教育活动中。</p>
					</div>
					<div class="list">
						<img src="static/lvya/solution/solution05.png" alt="">
						<p class="p1">方法+工具的共享</p>
						<p class="p2">打破传统区域平台提供资源与应用的共享模式，在核心技术的支撑下为区域提供“方法+工具”的共享。用承载先进教育理念的信息化工具，为学校赋能。</p>
					</div>
					<div class="list">
						<img src="static/lvya/solution/solution06.png" alt="">
						<p class="p1">可复制的教育领导力</p>
						<p class="p2">运用技术手段，沉淀优质示范性的优质方法和育人模式，在区校一体化平台的支撑下，可以将快速复制、示范到其他学校，让每一所学校都可以在区域的引领下，既能保持教育共性，又能在平台的支撑下快速实现校本化融合，极大推动区内各个学校的共同发展。</p>
					</div>
				</div>
			</div>
		</div>
		<div class="module module-04">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">案例介绍</p>
          <p class="line"></p>
        </div>
				<div class="top wow animate__slideInLeft">
					<div class="left">
						<img src="static/lvya/solution/solution01.png" alt="">
					</div>
					<div class="right">
						<div class="right-left">
							<p class="p1">新疆生产建设兵团第二师教育局</p>
							<p class="p2">区校一体化高质量发展平台</p>
							<p class="p3">“新疆信息化看兵团，兵团信息化看二师”，作为全疆信息化最有影响力的第二师教育局，第二师教育局携手金岭教育打造一区一校一工具一平台，兼顾区域的共性需求和学校个性发展。用工具承载华山中学的先进教育模式，在区域平台的规则和标准下推广复制给全区其他学校，带动全区其他学校教育高质量发展。</p>
						</div>
						<div class="right-right">
							<p>客户案例</p>
						</div>
					</div>
				</div>
				<div class="bottom wow animate__slideInRight">
					<div class="right">
						<div class="right-right">
							<p>客户案例</p>
						</div>
						<div class="right-left">
							<p class="p1">沈阳市教育局</p>
							<p class="p2">沈阳市教育公共服务平台</p>
							<p class="p3">以服务家长、教师、学校、减轻学校压力、区县配合、市局统筹管理为目的，搭建教育公共服务平台。平台依据国家教育行业数据标准，结合沈阳市实际情况建设学生、教师、学校信息库，建设基础信息、教育服务、涉外信息、体育艺术健康、教育安全、决策分析与风险管控等方面的管理功能，最终形成以学校为使用主体、区县监督、市局查看三级管理模式。</p>
						</div>
					</div>
					<div class="left">
						<img src="static/lvya/solution/solution02.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
		this.$nextTick(() => {
      let wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animate__animated',
				offset: '0',
				mobile: true,
				live: true
      })
      wow.init()
    })
	}
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.solution_div{
	width: 100%;
  min-width: 1200px;
  .top-img{
    line-height: 0;
    img{
      width: 100%;
      min-width: 1200px;
    }
  }
	.module{
    width: 100%;
    min-height: 200px;
    .w1200{
      width: 1200px;
      min-height: 100px;
      margin: 0 auto;
      padding: 50px 0 20px 0;
      box-sizing: border-box;
      .tip-text{
        text-align: center;
        .title{
          color: #222222;
          font-size: 30px;
          font-weight: bold;
        }
        .line{
          width: 63px;
          height: 5px;
          background: #19CA81;
          margin: 15px auto;
        }
        .text{
          font-size: 16px;
          color: #696969;
          line-height: 28px;
        }
        .w1090{
          width: 1090px;
          margin: 0 auto;
        }
      }
      .tip2-text{
        margin-top: 15px;
        .title{
          width: 1060px;
          margin: 0 auto;
          display: flex;
          align-content: center;
          img{
            width: 21px;
            height: 21px;
            margin-right: 10px;
            margin-top: 2px;
          }
          p{
            font-size: 18px;
            color: #222222;
            font-weight: bold;
          }
        }
        .text2{
          font-size: 16px;
          color: #696969;
          margin-top: 15px;
          line-height: 28px;
        }
        .w1060{
          width: 1060px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }
      .tip2-text:first-child{
        margin-top: 60px;
      }
      .img{
        width: 100%;
        margin-top: 60px;
      }
      .img2{
        width: 100%;
        margin-top: 10px;
      }
    }
  }
	.module-02{
		width: 100%;
    min-height: 200px;
		background: #fbfbfb;
		.list-flex{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 40px;
			margin-bottom: 40px;
			.list{
				width: 19%;
				min-height: 100px;
				border: 1px solid #ddd;
				padding: 30px 20px;
				box-sizing: border-box;
				text-align: center;
				background: #fff;
				.p1{
					color: #333;
					font-size: 16px;
					font-weight: 600;
					margin-top: 15px;
				}
				.p2{
					color: #666;
					font-size: 15px;
					line-height: 26px;
					margin-top: 12px;
				}
			}
		}
	}
	.module-03{
		width: 100%;
    min-height: 200px;
		.list-flex{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 40px;
			margin-bottom: 40px;
			.list{
				width: 32%;
				min-height: 100px;
				border-right: 1px solid #eee;
				padding: 30px 20px;
				box-sizing: border-box;
				text-align: center;
				background: #fff;
				.p1{
					color: #333;
					font-size: 16px;
					font-weight: 600;
					margin-top: 15px;
				}
				.p2{
					color: #666;
					font-size: 15px;
					line-height: 26px;
					margin-top: 12px;
				}
			}
		}
	}
	.module-04{
		min-height: 180px;
		background: #fbfbfb;
		.top{
			width: 100%;
			display: flex;
			background: #fff;
			align-items: center;
			margin-top: 50px;
			.left{}
			.right{
				display: flex;
				align-items: center;
				padding: 20px 20px;
				box-sizing: border-box;
				.right-left{
					border-right: 1px dashed #ccc;
					padding-right: 20px;
					.p1{
						font-size: 20px;
						color: #333;
						font-weight: 600;
					}
					.p2{
						color: #333;
						font-size: 16px;
						font-weight: 600;
						margin-top: 15px;
					}
					.p3{
						color: #666;
						font-size: 14px;
						line-height: 30px;
						margin-top: 15px;
					}
				}
				.right-right{
					padding-left: 20px;
				}
			}
		}
		.bottom{
			width: 100%;
			display: flex;
			background: #fff;
			align-items: center;
			margin-top: 50px;
			margin-bottom: 60px;
			.left{}
			.right{
				display: flex;
				align-items: center;
				padding: 20px 20px;
				box-sizing: border-box;
				.right-left{
					border-left: 1px dashed #ccc;
					padding-left: 20px;
					.p1{
						font-size: 20px;
						color: #333;
						font-weight: 600;
					}
					.p2{
						color: #333;
						font-size: 16px;
						font-weight: 600;
						margin-top: 15px;
					}
					.p3{
						color: #666;
						font-size: 14px;
						line-height: 30px;
						margin-top: 15px;
					}
				}
				.right-right{
					padding-right: 20px;
				}
			}
		}
	}
}
</style>
